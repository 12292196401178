import { SomeInputSpec } from "../form-specs";
import { getI18nLocaleObject } from "../i18n";
import namespaceList from "../i18n/namespaceList";

const dynamicImageSizeAutocomplete = (changeDynamicImageSize: string, dynamicImageSize: string): Array<SomeInputSpec<any, any>> => [
    {
        variable: changeDynamicImageSize,
        label: getI18nLocaleObject(namespaceList.widgetImageGallery, "changeDynamicImageSize"),
        default: false,
        type: "checkbox",
    },
    {
        label: getI18nLocaleObject(namespaceList.widgetImageGallery, "useDynamicImageCustomSize"),
        type: "paragraph",
        visible: (options: any) => options.changeDynamicImageSize,
    },
    {
        type: "select",
        variable: dynamicImageSize,
        label: getI18nLocaleObject(namespaceList.widgetImageGallery, "dynamicImageSize"),
        optionList: [
            {
                value: "large",
                label: getI18nLocaleObject(namespaceList.admin, "large"),
            },
            {
                value: "medium",
                label: getI18nLocaleObject(namespaceList.admin, "medium"),
            },
            {
                value: "original",
                label: getI18nLocaleObject(namespaceList.admin, "original"),
            },
        ],
        visible: (options: any) => options.changeDynamicImageSize,
    },
];

export default dynamicImageSizeAutocomplete;
