import * as React from "react";

import { FormSpec, localized, multiSelectStylePicker } from "../../../form-specs";
import { Image, ImageSpec, findImagesBySpecs } from "../../../media";
import { LocalizedTitleOptions, getWidgetTitleOptions, titleStylingOptions } from "../../../components/widgetTitleAndLabel/localizedLableTitle.util";
import { NumberMultiSelectOption, StringMultiSelectOption } from "../../mxts/selectOption.types";
import { PageWidgetSpec, Widget } from "../../";
import { ServerSideProps, contentTypeSelector, resortMultiSelector } from "../../../components/utils";
import { getImageModalTitleAndClasses, getImageSpecs, getOptions, loadImageOptions } from "./imageGallery.utils";
import { resourcesAutocomplete, unitsAutocomplete } from "../../../inputSpecs";

import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { WidgetOptions as DynamicContainerOptions } from "../../dynamic/container/container.types";
import ImageGalleryWidget from "./ImageGalleryWidget";
import { LocalizedContentBase } from "@maxxton/cms-api";
import { ResultOptions } from "../../widget";
import { SitemapPageLinkWidgetOptions } from "../../sitemap/sitemap.types";
import { WidgetGroup } from "../../widget.enum";
import chooseImageTypeMultiSelectSpec from "../../../inputSpecs/imageTypesMultiSelect";
import dynamicImageSizeAutocomplete from "../../../inputSpecs/dynamicImageSizeAutocomplete";
import { getI18nLocaleObject } from "../../../i18n";
import { getImageAndSpec } from "../image/imageWidget.utils";
import namespaceList from "../../../i18n/namespaceList";

type slideIndex = "none" | "dots" | "slide number";

export enum SlideIndicators {
    BULLETS = "bullets",
    SLIDENUMBER = "slideNumber",
    NOINDICATORS = "noindicators",
}

export const TARGETS = ["image-gallery"];

export interface LocalizedTitle {
    label: string;
    locale: string;
}
export interface LocalizedButton extends LocalizedContentBase {
    text?: string;
    optionalButtonText?: string;
}
export interface LocalizedMainImage extends LocalizedContentBase {
    mainImageContent?: string;
}
interface StyleOptions {
    value: string | null;
    text: string;
}

export interface ImageOptions {
    url: string;
    mediaType: string;
    imageType: string;
    id: string;
    imageUrl: string;
    alt?: string;
    friendlyName?: string;
    className: string;
    height?: number;
    width?: number;
    fileName?: string;
    imageId?: number;
}

export interface LocalizedOptions extends LocalizedContentBase {
    localizedImageGallery: ImageSpec[];
}

export interface WidgetOptions extends LocalizedTitleOptions {
    imageSpecs?: ImageSpec[];
    imageSpec?: ImageSpec;
    slideIndex: string;
    dynamicIndicators: boolean;
    localizedTitle: LocalizedTitle[];
    localizedButton: LocalizedButton[];
    localizedMainImage: LocalizedMainImage[];
    showDynamicImages: boolean;
    contentType?: string;
    changeDynamicImageSize?: boolean;
    resortMultiSelector?: NumberMultiSelectOption[];
    resourceId?: number;
    unitId?: number;
    styleIds?: StyleOptions[];
    sliderIndicators: string;
    showArrows: boolean;
    arrowsVerticalPosition: string;
    arrowsTogether: boolean;
    arrowsOutside: boolean;
    arrowsHorizontalPosition: string;
    singleImageSlider: boolean;
    multiImageSlider: boolean;
    useOverlayThumbnailForVideo: boolean;
    slidesPerView: number;
    spaceBetween: number;
    showImageGalleryLayout: boolean;
    placementOfMultipleImages: string;
    dynamicHeaderImage: boolean;
    styleYPlacement: number;
    hideFigCaption: boolean;
    figCaptionFontSize: string;
    displayButton: boolean;
    hideOverlay: boolean;
    showOverlay: boolean;
    localizedOptions: LocalizedOptions[];
    removeHoverEffect: boolean;
    dynamicImageSize?: string;
    hideMediaTypeMap?: boolean;
    chooseImageTypes?: boolean;
    imageTypes?: StringMultiSelectOption[];
    arrowStyle?: string;
}

const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "image-widget-options",
    name: getI18nLocaleObject(namespaceList.widgetImageGallery, "imageGalleryWidgetOptions"),
    pluralName: getI18nLocaleObject(namespaceList.widgetImageGallery, "imageGalleryWidgetOptions"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.widgetImageGallery, "general"),
                    properties: [
                        [
                            {
                                type: "imagegallery",
                                label: getI18nLocaleObject(namespaceList.widgetImageGallery, "images"),
                                variable: "imageSpecs",
                            },
                            localized({
                                variable: "localizedOptions",
                                tabContent: [
                                    {
                                        type: "imagegallery",
                                        label: getI18nLocaleObject(namespaceList.widgetImageGallery, "images"),
                                        variable: "localizedImageGallery",
                                    },
                                ],
                            }),
                            {
                                variable: "showDynamicImages",
                                label: getI18nLocaleObject(namespaceList.widgetImageGallery, "showDynamicImages"),
                                default: false,
                                type: "checkbox",
                            },
                            {
                                variable: "singleImageSlider",
                                label: getI18nLocaleObject(namespaceList.widgetImageGallery, "singleImageSlider"),
                                type: "checkbox",
                                groupName: "sliderAndHeaderPhotoGroup",
                                visible: (options: WidgetOptions) => !options.multiImageSlider && !options.dynamicHeaderImage,
                            },
                            {
                                variable: "dynamicHeaderImage",
                                label: getI18nLocaleObject(namespaceList.widgetImageGallery, "dynamicHeaderImage"),
                                type: "checkbox",
                                groupName: "sliderAndHeaderPhotoGroup",
                            },
                            {
                                type: "paragraph",
                                label: getI18nLocaleObject(namespaceList.widgetImageGallery, "dynamicHeaderParagraph"),
                                groupName: "sliderAndHeaderPhotoGroup",
                                visible: (options: WidgetOptions) => options.dynamicHeaderImage,
                            },
                            {
                                variable: "multiImageSlider",
                                label: getI18nLocaleObject(namespaceList.widgetImageGallery, "multiImageSlider"),
                                type: "checkbox",
                                groupName: "sliderAndHeaderPhotoGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.widgetImageGallery, "sliderAndHeaderPhotoGroupTitle"),
                                visible: (options: WidgetOptions) => !options.showImageGalleryLayout && !options.singleImageSlider,
                            },

                            {
                                variable: "slidesPerView",
                                label: getI18nLocaleObject(namespaceList.widgetSlideShow, "slidesPerView"),
                                type: "number",
                                groupName: "sliderAndHeaderPhotoGroup",
                                visible: (options: WidgetOptions) => !options.showImageGalleryLayout && options.multiImageSlider,
                            },
                            {
                                variable: "spaceBetween",
                                label: getI18nLocaleObject(namespaceList.widgetSlideShow, "spaceBetween"),
                                type: "number",
                                groupName: "sliderAndHeaderPhotoGroup",
                                visible: (options: WidgetOptions) => !options.showImageGalleryLayout && options.multiImageSlider,
                            },
                            {
                                variable: "showImageGalleryLayout",
                                label: getI18nLocaleObject(namespaceList.widgetImageGallery, "showImageGalleryLayout"),
                                type: "checkbox",
                                visible: (options: WidgetOptions) => !options.singleImageSlider,
                            },
                            {
                                variable: "placementOfMultipleImages",
                                label: getI18nLocaleObject(namespaceList.widgetResultsImageGallery, "placementOfMultipleImages"),
                                type: "radioImage",
                                default: "right",
                                visible: (options: WidgetOptions) => options.showImageGalleryLayout && !options.singleImageSlider,
                                radioOptions: [
                                    {
                                        // jscpd:ignore-start
                                        value: "right",
                                        label: getI18nLocaleObject(namespaceList.admin, "right"),
                                        element: (
                                            <svg className="right" width="100%" height="80">
                                                <rect fill="rgba(153,153,153,0.5)" x="0" y="0" width="60%" height="80"></rect>
                                                <rect fill="rgba(214,208,208,0.5)" x="62%" y="0" width="38%" height="46%"></rect>
                                                <rect fill="rgba(214,208,208,0.5)" x="62%" y="52%" width="38%" height="48%"></rect>
                                            </svg>
                                        ),
                                    },
                                    {
                                        value: "left",
                                        label: getI18nLocaleObject(namespaceList.admin, "left"),
                                        element: (
                                            <svg className="left" width="100%" height="80">
                                                <rect fill="rgba(153,153,153,0.5)" x="40%" y="0" width="60%" height="100%" />
                                                <rect fill="rgba(214,208,208,0.5)" x="0" y="0" width="38%" height="46%" />
                                                <rect fill="rgba(214,208,208,0.5)" x="0" y="52%" width="38%" height="48%" />
                                            </svg>
                                        ),
                                    },
                                    {
                                        value: "top",
                                        label: getI18nLocaleObject(namespaceList.admin, "top"),
                                        element: (
                                            <svg className="top" width="100%" height="80">
                                                <rect fill="rgba(214,208,208,0.5)" x="0" y="0" width="49%" height="46%" />
                                                <rect fill="rgba(214,208,208,0.5)" x="51%" y="0" width="49%" height="46%" />
                                                <rect fill="rgba(153,153,153,0.5)" x="0" y="52%" width="100%" height="48%" />
                                            </svg>
                                        ),
                                    },
                                    {
                                        value: "bottom",
                                        label: getI18nLocaleObject(namespaceList.admin, "bottom"),
                                        element: (
                                            <svg className="bottom" width="100%" height="80">
                                                <rect fill="rgba(153,153,153,0.5)" x="0" y="0" width="100%" height="48%" />
                                                <rect fill="rgba(214,208,208,0.5)" x="0" y="52%" width="49%" height="46%" />
                                                <rect fill="rgba(214,208,208,0.5)" x="51%" y="52%" width="49%" height="46%" />
                                            </svg>
                                        ),
                                        // jscpd:ignore-end
                                    },
                                    {
                                        value: "right-five-layout",
                                        label: getI18nLocaleObject(namespaceList.admin, "right"),
                                        element: (
                                            <svg className="right" width="100%" height="80">
                                                <rect fill="rgba(153,153,153,0.5)" x="0" y="0" width="60%" height="80"></rect>
                                                <rect fill="rgba(214,208,208,0.5)" x="62%" y="0" width="18%" height="46%"></rect>
                                                <rect fill="rgba(214,208,208,0.5)" x="81%" y="0" width="18%" height="46%"></rect>
                                                <rect fill="rgba(214,208,208,0.5)" x="62%" y="52%" width="18%" height="46%"></rect>
                                                <rect fill="rgba(214,208,208,0.5)" x="81%" y="52%" width="18%" height="46%"></rect>
                                            </svg>
                                        ),
                                    },
                                    {
                                        value: "left-five-layout",
                                        label: getI18nLocaleObject(namespaceList.admin, "left"),
                                        element: (
                                            <svg className="left" width="100%" height="80">
                                                <rect fill="rgba(153,153,153,0.5)" x="40%" y="0" width="60%" height="100%"></rect>
                                                <rect fill="rgba(214,208,208,0.5)" x="19%" y="0" width="18%" height="46%"></rect>
                                                <rect fill="rgba(214,208,208,0.5)" x="0" y="0" width="18%" height="46%"></rect>
                                                <rect fill="rgba(214,208,208,0.5)" x="19%" y="52%" width="18%" height="48%"></rect>
                                                <rect fill="rgba(214,208,208,0.5)" x="0" y="52%" width="18%" height="48%"></rect>
                                            </svg>
                                        ),
                                    },
                                    {
                                        value: "top-five-layout",
                                        label: getI18nLocaleObject(namespaceList.admin, "top"),
                                        element: (
                                            <svg className="top" width="100%" height="80">
                                                <rect fill="rgba(214,208,208,0.5)" x="0" y="0" width="24%" height="46%"></rect>
                                                <rect fill="rgba(214,208,208,0.5)" x="25%" y="0" width="24%" height="46%"></rect>
                                                <rect fill="rgba(214,208,208,0.5)" x="51%" y="0" width="24%" height="46%"></rect>
                                                <rect fill="rgba(214,208,208,0.5)" x="76%" y="0" width="24%" height="46%"></rect>
                                                <rect fill="rgba(153,153,153,0.5)" x="0" y="52%" width="100%" height="48%"></rect>
                                            </svg>
                                        ),
                                    },
                                    {
                                        value: "bottom-five-layout",
                                        label: getI18nLocaleObject(namespaceList.admin, "bottom"),
                                        element: (
                                            <svg className="bottom" width="100%" height="80">
                                                <rect fill="rgba(153,153,153,0.5)" x="0" y="0" width="100%" height="48%"></rect>
                                                <rect fill="rgba(214,208,208,0.5)" x="0" y="52%" width="24%" height="46%"></rect>
                                                <rect fill="rgba(214,208,208,0.5)" x="25%" y="52%" width="24%" height="46%"></rect>
                                                <rect fill="rgba(214,208,208,0.5)" x="51%" y="52%" width="24%" height="46%"></rect>
                                                <rect fill="rgba(214,208,208,0.5)" x="76%" y="52%" width="24%" height="46%"></rect>
                                            </svg>
                                        ),
                                    },
                                ],
                            },
                            localized({
                                variable: "localizedMainImage",
                                visible: (options: WidgetOptions) => options.showImageGalleryLayout,
                                tabContent: [
                                    {
                                        variable: "mainImageContent",
                                        label: getI18nLocaleObject(namespaceList.widgetImageGallery, "mainImageContent"),
                                        type: "text",
                                    },
                                ],
                            }),
                            {
                                variable: "useOverlayThumbnailForVideo",
                                label: getI18nLocaleObject(namespaceList.widgetImageGallery, "useOverlayThumbnailForVideo"),
                                type: "checkbox",
                                groupName: "OverlayThumbnailForVideoGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.widgetImageGallery, "overlayThumbnailForVideoGroupTitle"),
                            },
                            {
                                type: "image",
                                label: getI18nLocaleObject(namespaceList.widgetImage, "image"),
                                variable: "imageSpec",
                                groupName: "OverlayThumbnailForVideoGroup",
                                visible: (options: WidgetOptions) => options.useOverlayThumbnailForVideo,
                            },
                            {
                                variable: "dynamicIndicators",
                                label: getI18nLocaleObject(namespaceList.widgetImageGallery, "dynamicIndicators"),
                                default: false,
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.slideIndex === "dots per slide",
                            },
                            ...getWidgetTitleOptions<WidgetOptions>(),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "styling"),
                    properties: [
                        [
                            multiSelectStylePicker("styleIds", TARGETS),
                            ...titleStylingOptions<WidgetOptions>(),
                            {
                                type: "number",
                                variable: "styleYPlacement",
                                default: "center",
                                label: getI18nLocaleObject(namespaceList.admin, "styleYPlacement"),
                                visible: (options: any) => options.dynamicHeaderImage,
                            },
                            {
                                variable: "displayButton",
                                label: getI18nLocaleObject(namespaceList.admin, "displayButton"),
                                type: "checkbox",
                                visible: (options: WidgetOptions) => !options.multiImageSlider && !options.hideOverlay,
                            },
                            localized({
                                variable: "localizedButton",
                                visible: (options: WidgetOptions) => options.displayButton && !options.multiImageSlider,
                                tabContent: [
                                    {
                                        variable: "optionalButtonText",
                                        label: getI18nLocaleObject(namespaceList.widgetImageGallery, "optionalButtonText"),
                                        type: "text",
                                    },
                                ],
                            }),
                            {
                                variable: "hideOverlay",
                                label: getI18nLocaleObject(namespaceList.admin, "hideOverlay"),
                                type: "checkbox",
                                visible: (options: WidgetOptions) => !options.multiImageSlider && !options.singleImageSlider && !options.displayButton,
                            },
                            {
                                variable: "showOverlay",
                                label: getI18nLocaleObject(namespaceList.admin, "showOverlay"),
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.singleImageSlider && !options.displayButton,
                            },
                            {
                                variable: "hideFigCaption",
                                label: getI18nLocaleObject(namespaceList.widgetImageGallery, "hideFigCaption"),
                                type: "checkbox",
                            },
                            {
                                variable: "figCaptionFontSize",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "fontSize"),
                                type: "select",
                                default: "default-font-size",
                                visible: (options: WidgetOptions) => options.showImageGalleryLayout && !options.hideFigCaption,
                                optionList: [
                                    {
                                        value: "default-font-size",
                                        label: getI18nLocaleObject(namespaceList.admin, "defaultFontSize"),
                                    },
                                    {
                                        value: "micro-font-size",
                                        label: getI18nLocaleObject(namespaceList.admin, "microFontSize"),
                                    },
                                    {
                                        value: "xx-small-font-size",
                                        label: getI18nLocaleObject(namespaceList.admin, "xxSmallFontSize"),
                                    },
                                    {
                                        value: "x-small-font-size",
                                        label: getI18nLocaleObject(namespaceList.admin, "xSmallFontSize"),
                                    },
                                    {
                                        value: "small-font-size",
                                        label: getI18nLocaleObject(namespaceList.admin, "smallFontSize"),
                                    },
                                    {
                                        value: "h6-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h6Fonts"),
                                    },
                                    {
                                        value: "h5-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h5Fonts"),
                                    },
                                    {
                                        value: "h4-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h4Fonts"),
                                    },
                                    {
                                        value: "h3-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h3Fonts"),
                                    },
                                    {
                                        value: "h2-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h2Fonts"),
                                    },
                                    {
                                        value: "h1-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h1Fonts"),
                                    },
                                ],
                            },
                            {
                                variable: "removeHoverEffect",
                                label: getI18nLocaleObject(namespaceList.admin, "removeHoverEffect"),
                                type: "checkbox",
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetImageGallery, "singleSlider"),
                    visible: (options: WidgetOptions) => options.singleImageSlider || options.multiImageSlider,
                    properties: [
                        [
                            {
                                variable: "sliderIndicators",
                                label: getI18nLocaleObject(namespaceList.widgetImageGallery, "sliderIndicators"),
                                type: "select",
                                default: "noindicators" as slideIndex,
                                optionList: [
                                    {
                                        value: "noindicators",
                                        label: getI18nLocaleObject(namespaceList.widgetImageGallery, "noindicators"),
                                    },
                                    {
                                        value: "bullets",
                                        label: getI18nLocaleObject(namespaceList.widgetImageGallery, "bullets"),
                                    },
                                    {
                                        value: "slideNumber",
                                        label: getI18nLocaleObject(namespaceList.widgetImageGallery, "slideNumber"),
                                    },
                                ],
                            },
                            {
                                variable: "showArrows",
                                label: getI18nLocaleObject(namespaceList.widgetImageGallery, "showArrows"),
                                type: "checkbox",
                                groupName: "showArrowsGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.widgetImageGallery, "showArrowsGroupTitle"),
                            },
                            {
                                variable: "arrowsVerticalPosition",
                                label: getI18nLocaleObject(namespaceList.widgetImageGallery, "arrowsVerticalPosition"),
                                type: "select",
                                default: "center",
                                visible: (options: WidgetOptions) => options.showArrows,
                                groupName: "showArrowsGroup",
                                optionList: [
                                    {
                                        value: "top",
                                        label: getI18nLocaleObject(namespaceList.admin, "top"),
                                    },
                                    {
                                        value: "center",
                                        label: getI18nLocaleObject(namespaceList.admin, "center"),
                                    },
                                    {
                                        value: "bottom",
                                        label: getI18nLocaleObject(namespaceList.admin, "bottom"),
                                    },
                                ],
                            },
                            {
                                variable: "arrowsTogether",
                                label: getI18nLocaleObject(namespaceList.widgetImageGallery, "arrowsTogether"),
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.showArrows,
                                groupName: "showArrowsGroup",
                            },
                            {
                                variable: "arrowsOutside",
                                label: getI18nLocaleObject(namespaceList.widgetImageGallery, "arrowsOutside"),
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.showArrows && options.arrowsTogether,
                                groupName: "showArrowsGroup",
                            },
                            {
                                variable: "arrowsHorizontalPosition",
                                label: getI18nLocaleObject(namespaceList.widgetImageGallery, "arrowsHorizontalPosition"),
                                type: "select",
                                default: "center",
                                visible: (options: WidgetOptions) => options.showArrows && options.arrowsTogether,
                                groupName: "showArrowsGroup",
                                optionList: [
                                    {
                                        value: "left",
                                        label: getI18nLocaleObject(namespaceList.admin, "left"),
                                    },
                                    {
                                        value: "center",
                                        label: getI18nLocaleObject(namespaceList.admin, "center"),
                                    },
                                    {
                                        value: "right",
                                        label: getI18nLocaleObject(namespaceList.admin, "right"),
                                    },
                                ],
                            },
                            {
                                variable: "arrowStyle",
                                label: getI18nLocaleObject(namespaceList.widgetImageGallery, "arrowStyle"),
                                type: "select",
                                default: "default",
                                visible: (options: WidgetOptions) => options.showArrows,
                                groupName: "showArrowsGroup",
                                optionList: [
                                    {
                                        value: "default",
                                        label: getI18nLocaleObject(namespaceList.admin, "default"),
                                    },
                                    {
                                        value: "without-background",
                                        label: getI18nLocaleObject(namespaceList.admin, "withoutBackgroundStyling"),
                                    },
                                    {
                                        value: "round",
                                        label: getI18nLocaleObject(namespaceList.admin, "roundStyling"),
                                    },
                                    {
                                        value: "round-ghost",
                                        label: getI18nLocaleObject(namespaceList.admin, "roundGhostStyling"),
                                    },
                                ],
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetImageGallery, "modal"),
                    properties: [
                        [
                            localized({
                                variable: "localizedTitle",
                                tabContent: [
                                    {
                                        variable: "label",
                                        label: getI18nLocaleObject(namespaceList.widgetImageGallery, "titleForModalPopup"),
                                        type: "text",
                                        placeholder: getI18nLocaleObject(namespaceList.admin, "placeholderForTitle"),
                                    },
                                ],
                            }),
                            {
                                variable: "slideIndex",
                                label: getI18nLocaleObject(namespaceList.widgetImageGallery, "slideIndex"),
                                type: "select",
                                default: "none" as slideIndex,
                                optionList: [
                                    {
                                        value: "none",
                                        label: getI18nLocaleObject(namespaceList.widgetImageGallery, "none"),
                                    },
                                    {
                                        value: "dots per slide",
                                        label: getI18nLocaleObject(namespaceList.widgetImageGallery, "bullets"),
                                    },
                                    {
                                        value: "slide number",
                                        label: getI18nLocaleObject(namespaceList.widgetImageGallery, "slideNumber"),
                                    },
                                ],
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetImageGallery, "dynamicImage"),
                    visible: (options: WidgetOptions) => options.showDynamicImages,
                    properties: [
                        [
                            {
                                variable: "hideMediaTypeMap",
                                label: getI18nLocaleObject(namespaceList.widgetImageGallery, "hideMediaTypeMap"),
                                type: "checkbox",
                            },
                            contentTypeSelector("contentType"),
                            resortMultiSelector("resortMultiSelector"),
                            resourcesAutocomplete("resourceId"),
                            unitsAutocomplete("unitId"),
                            ...dynamicImageSizeAutocomplete("changeDynamicImageSize", "dynamicImageSize"),
                            ...chooseImageTypeMultiSelectSpec(),
                        ],
                    ],
                },
            ],
        },
    ],
};

export const imagesWidget: PageWidgetSpec<WidgetOptions> = {
    id: "imagegallery",
    type: "page",
    widgetGroup: WidgetGroup.CONTENT,
    name: getI18nLocaleObject(namespaceList.widgetImageGallery, "imageGalleryWidget"),
    description: getI18nLocaleObject(namespaceList.widgetImageGallery, "imageGalleryWidgetDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): WidgetOptions => ({
        slideIndex: "none" as slideIndex,
        dynamicIndicators: false,
        localizedTitle: [],
        localizedButton: [],
        localizedMainImage: [],
        showDynamicImages: false,
        singleImageSlider: false,
        sliderIndicators: "noindicators",
        showArrows: false,
        arrowsVerticalPosition: "center",
        arrowsTogether: false,
        arrowsOutside: false,
        arrowsHorizontalPosition: "center",
        multiImageSlider: false,
        useOverlayThumbnailForVideo: false,
        slidesPerView: 1,
        spaceBetween: 0,
        showImageGalleryLayout: false,
        placementOfMultipleImages: "right",
        styleIds: [],
        dynamicHeaderImage: false,
        styleYPlacement: 50,
        hideFigCaption: false,
        figCaptionFontSize: "default-font-size",
        displayButton: false,
        hideOverlay: false,
        showOverlay: false,
        localizedOptions: [],
        removeHoverEffect: false,
        arrowStyle: "default",
    }),

    async render(
        widget: Widget<WidgetOptions>,
        context: CMSProvidedProperties,
        sitemapPageLinkWidgetOptions?: SitemapPageLinkWidgetOptions,
        resultOptions?: ResultOptions,
        dynamicContainerOptions?: DynamicContainerOptions
    ) {
        const { options } = widget;
        const imageSpecs = getImageSpecs(context, options);
        const { styleClasses, imageModalTitle } = getImageModalTitleAndClasses(context, widget.options);
        let imageOptions: ImageOptions[] = [];
        const { image, imageSpec } = await getImageAndSpec(context, options, null);
        const images: Image[] = imageSpecs ? await findImagesBySpecs(context, imageSpecs) : [];
        imageOptions = imageSpecs ? images.map((img: Image, index) => getOptions(imageSpecs!, img, index, context)) : [];
        const reduxStore = context?.reduxStore?.store?.getState();
        const props: ServerSideProps<"ImageGalleryWidgetProps"> = {
            context,
            options,
            dynamicFilter: reduxStore?.dynamicFilter,
            availabilityState: reduxStore?.availabilityState,
            myEnvState: reduxStore?.myEnvState,
        };
        imageOptions = (await loadImageOptions(props, imageOptions))?.imageOptions;

        return (
            <ImageGalleryWidget
                imageOptions={imageOptions}
                imageSpecs={imageSpecs}
                context={context}
                imageModalTitle={imageModalTitle}
                options={widget.options}
                dynamicContainerOptions={dynamicContainerOptions}
                className={styleClasses}
                widgetOptionsForm={widgetOptionsForm}
                widgetId={widget._id}
                image={image}
                imageSpec={imageSpec}
            />
        );
    },
};
