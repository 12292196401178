/* eslint-disable max-lines-per-function */
import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classNames from "classnames";

import { Button, Card, CardBody, CardImg, CardTitle, Input, Modal, ModalBody, ModalHeader, UncontrolledAlert } from "reactstrap";
import { Content, getContent, isContentFilterChanged } from "../../../utils/content.util";
import { GetEditableModal, getEditableButton } from "../../dynamic/container/myenv/myEnvContainerEditable.util";
import { Image, ImageSpec, fallbackImage } from "../../../media";
import { LocalizedButton, LocalizedMainImage, SlideIndicators, WidgetOptions } from "./";
import ReactIdSwiper, { ReactIdSwiperProps } from "react-id-swiper";
import { Resort, getMxtsEnv } from "../../mxts";
import { forceUpdateSwiper, getImageModalTitleAndClasses, getImageOptions, getImageSpecs, getImageUrl, getMXTSOptions, loadImageOptions } from "./imageGallery.utils";
import { getHideWidgetClass, isClientLoggedIn } from "../../../components/utils";
import { getI18nLocaleString, wrapProps } from "../../../i18n";

import { AvailabilityState } from "../../../redux/reducers/availability.types";
import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { WidgetOptions as DynamicContainerOptions } from "../../dynamic/container/container.types";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { DynamicMyEnvContainerChildOptions } from "../../dynamic/container/myenv/dynamicMyEnvContainer.types";
// Interface
import { ImageOptions } from "./index";
import { ImageType } from "../../../inputSpecs/imageTypesMultiSelect";
import { Loader } from "../../../components/Loader";
import LocalizedTitleAndLabel from "../../../components/widgetTitleAndLabel/LocalizedLableTitle";
import { MxtsApi } from "@maxxton/cms-mxts-api";
import { MyEnvState } from "../../../redux/reducers/myEnv/myEnvState";
import { PageWidgetBaseProps } from "../pageWidget.types";
import { State } from "../../../redux";
import { VideoPlayer } from "./VideoPlayer";
import { connect } from "react-redux";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import { isEqual } from "lodash";
import loadable from "@loadable/component";
import { loadableRetry } from "../../../utils/loadableComponents.util";
import namespaceList from "../../../i18n/namespaceList";
import { renderThumbnailWithPlayButton } from "./imageGalleryVideo.utils";
import withEditModal from "../../../components/frontend-editing/WithEditModal";

const GalleryModal = loadable(() => loadableRetry(() => import("./GalleryModal")), {
    resolveComponent: ({ GalleryModal }) => GalleryModal,
});

const Swiper = loadable(() => loadableRetry(() => import("react-id-swiper")), {
    resolveComponent: (Swiper) => Swiper.default as React.ForwardRefExoticComponent<any>,
});

export interface ImageGalleryBaseProps extends PageWidgetBaseProps<WidgetOptions> {
    dynamicContainerOptions: DynamicContainerOptions | undefined;
    imageOptions: ImageOptions[];
    context: CMSProvidedProperties;
    imageSpecs?: ImageSpec[];
    image: Image | null;
    imageSpec: ImageSpec;
    imageModalTitle: string;
    className: string;
    childOptions?: DynamicMyEnvContainerChildOptions;
    handleFrontendEditModal?: () => void;
}

export interface ImageGalleryProps extends ImageGalleryBaseProps, ImageGalleryStoreProps {}

export interface ImageGalleryStoreProps {
    myEnvState: MyEnvState;
    dynamicFilter: DynamicFilter;
    availabilityState: AvailabilityState;
    isFrontendEditable?: boolean;
}

interface EditableModalImagePickerState {
    isFetching?: boolean;
    searchResults?: Image[];
    message?: string;
    selectedImage: Image | null;
    isDelete?: "Failed" | "Success" | undefined;
    isImageUploaded?: "Failed" | "Success" | undefined;
    isImageFileTypeInvalid?: boolean;
    isUploadingAndDeleting?: boolean;
}

interface ImageGalleryState extends EditableModalImagePickerState {
    showModal: boolean;
    imageOptions: ImageOptions[];
    disableWidget: boolean;
    captionCheck: boolean;
    slidePosition: number;
    setSwiperEvent: boolean;
    imageLoading: boolean;
    isEditModalOpen?: boolean;
    activeTab?: number;
    imageSpecs?: ImageSpec[];
    imageModalTitle: string;
    className: string;
    isFrontendEditable?: boolean;
    showVideoModal?: boolean;
    modalVideoUrl?: string;
}

export enum MediaType {
    IMAGE = "IMAGE",
    VIDEO = "VIDEO",
    MAP = "MAP",
}

interface SliderParams {
    spaceBetween: number;
    slidesPerView: number;
    lazy: boolean;
    preloadImages: boolean;
}

// TODO, we use way too many different ways of fetching an image
// Using imageOption.url ImageOptions.url image.imageUrl etc.
// Created: MCMS-5040 for the same
// jscpd:ignore-start
class ImageGalleryBase extends React.PureComponent<ImageGalleryProps, ImageGalleryState> {
    // Swiper instance
    private swiper: typeof ReactIdSwiper = React.createRef();
    private timer: NodeJS.Timer | undefined = undefined;

    public constructor(props: ImageGalleryProps) {
        super(props);
        this.state = {
            showModal: false,
            imageOptions: props.imageOptions,
            imageModalTitle: props.imageModalTitle,
            className: props.className,
            imageSpecs: props.imageSpecs,
            isFrontendEditable: props.isFrontendEditable,
            disableWidget: true,
            captionCheck: false,
            slidePosition: 0,
            setSwiperEvent: false,
            imageLoading: false,
            activeTab: 0,
            selectedImage: null,
        };
    }

    public componentDidMount() {
        loadImageOptions(this.props, this.state.imageOptions).then(({ imageOptions }) => {
            this.setState({ imageOptions });
        });
        this.setState({ disableWidget: !isClientLoggedIn(), setSwiperEvent: !this.swiper?.current });
        forceUpdateSwiper(this.swiper);
    }

    private updatePropsCallback = (updatedProps: ImageGalleryProps) => {
        const { options, context, isFrontendEditable } = updatedProps;
        const imageSpecs = getImageSpecs(context, options);
        const { styleClasses, imageModalTitle } = getImageModalTitleAndClasses(context, options);
        this.setState({ className: styleClasses, imageModalTitle, imageSpecs }, async () => {
            const imageOptions = await getImageOptions(imageSpecs as ImageSpec[], context);
            loadImageOptions(this.props, this.state.imageOptions).then(({ imageOptions }) => {
                this.setState({ imageOptions });
            });
        });
    };

    public componentDidUpdate(prevProps: ImageGalleryProps) {
        const { imageOptions, setSwiperEvent } = this.state;
        const { useOverlayThumbnailForVideo } = this.props.options;
        if (imageOptions.length && setSwiperEvent && this.swiper?.current?.swiper) {
            // Workaround to fix inconsistent click on Swiper slides Ref: https://github.com/kidjp85/react-id-swiper/issues/468
            this.swiper.current.swiper.on("slideChangeTransitionEnd", () => {
                this.swiper.current.swiper.slideToLoop(this.swiper.current.swiper.realIndex, 0, false);
            });
            this.setState({ setSwiperEvent: false });
        }

        if (isContentFilterChanged(prevProps, this.props)) {
            loadImageOptions(this.props, this.state.imageOptions).then(({ imageOptions }) => {
                this.setState({ imageOptions });
            });
        }
        if (!isEqual(this.props.options, prevProps.options)) {
            loadImageOptions(this.props, this.state.imageOptions).then(({ imageOptions }) => {
                this.setState({ imageOptions });
            });
            this.updatePropsCallback(this.props);
            this.forceUpdate();
        }
        if (useOverlayThumbnailForVideo) {
            document.addEventListener("DOMContentLoaded", () => {
                const playButton = document.getElementById("PlayButton");
                const thumbnailWithPlayVideo = document.getElementById("thumbnailWithPlayVideo") as HTMLIFrameElement | null;

                if (playButton && thumbnailWithPlayVideo) {
                    playButton.addEventListener("click", (ev) => {
                        if (thumbnailWithPlayVideo) {
                            ev.preventDefault();
                            ev.stopPropagation();
                        }
                    });
                }
            });
        }
    }

    // eslint-disable-next-line max-lines-per-function
    public render(): JSX.Element | null {
        const {
            options: widgetOptions,
            context: { currentLocale, site },
            childOptions,
            handleFrontendEditModal,
            isFrontendEditable,
        } = this.props;
        const { className } = this.state;
        const { disableWidget, imageLoading, isEditModalOpen, imageModalTitle, showVideoModal } = this.state;
        const localizedButton: LocalizedButton | null = getLocalizedContent({ site, currentLocale, localizedContent: widgetOptions.localizedButton });
        const { enableWidgetTitle, useTitleHeadings, styleWidgetTitle, fontColor } = widgetOptions;
        const localizedWidgetTitle: string = getLocalizedContent({ site, currentLocale, localizedContent: widgetOptions.localizedWidgetTitle || [], keys: ["widgetTitleText"] })?.widgetTitleText || "";
        const hideWidget = getHideWidgetClass(widgetOptions, disableWidget);
        const { useOverlayThumbnailForVideo } = this.props.options;
        if (hideWidget === null) {
            return null;
        }
        const singleSliderParams: ReactIdSwiperProps = {};
        singleSliderParams.spaceBetween = 0;
        singleSliderParams.slidesPerView = 1;
        singleSliderParams.loop = true;
        singleSliderParams.lazy = true;
        singleSliderParams.preloadImages = false;
        const multiSliderParams: ReactIdSwiperProps = {
            spaceBetween: widgetOptions.spaceBetween ? +widgetOptions.spaceBetween : 1,
            slidesPerView: widgetOptions.slidesPerView ? widgetOptions.slidesPerView : 1,
            lazy: true,
            preloadImages: true,
        };
        const sliderParams = widgetOptions.singleImageSlider ? singleSliderParams : multiSliderParams;
        if (widgetOptions.sliderIndicators === SlideIndicators.BULLETS) {
            singleSliderParams.pagination = {
                el: ".swiper-pagination",
                clickable: true,
                dynamicBullets: widgetOptions.dynamicIndicators ? true : false,
            };
        } else if (widgetOptions.sliderIndicators === SlideIndicators.SLIDENUMBER) {
            singleSliderParams.pagination = {
                el: ".swiper-pagination",
                type: "fraction",
            };
        }
        if (widgetOptions.sliderIndicators === SlideIndicators.BULLETS) {
            multiSliderParams.pagination = {
                el: ".swiper-pagination",
                clickable: true,
                dynamicBullets: widgetOptions.dynamicIndicators ? true : false,
            };
        } else if (widgetOptions.sliderIndicators === SlideIndicators.SLIDENUMBER) {
            multiSliderParams.pagination = {
                el: ".swiper-pagination",
                type: "fraction",
            };
        }
        let { imageOptions } = this.state;
        imageOptions = imageOptions.filter((imageOption) => imageOption.id !== "");
        if ((widgetOptions.singleImageSlider || widgetOptions.multiImageSlider || widgetOptions.dynamicHeaderImage) && imageOptions.length) {
            const { options } = this.props;
            return (
                // eslint-disable-next-line max-len
                <div
                    className={classNames(className, {
                        ["img-position-" + widgetOptions.styleYPlacement]: widgetOptions.dynamicHeaderImage && !!widgetOptions.styleYPlacement,
                        "dynamic-header-image": widgetOptions.dynamicHeaderImage,
                        "caption-added": this.state.captionCheck === true,
                        "swiper-container": !widgetOptions.dynamicHeaderImage,
                        "indicator-bullets": widgetOptions.sliderIndicators === SlideIndicators.BULLETS,
                        "indicator-numbers": widgetOptions.sliderIndicators === SlideIndicators.SLIDENUMBER,
                        "indicator-none": widgetOptions.sliderIndicators === SlideIndicators.NOINDICATORS,
                        "multi-img-slider": widgetOptions.multiImageSlider,
                    })}
                >
                    <LocalizedTitleAndLabel
                        localizedTitle={localizedWidgetTitle}
                        enableWidgetTitle={enableWidgetTitle}
                        useTitleHeadings={useTitleHeadings}
                        styleWidgetTitle={styleWidgetTitle}
                        className={classNames("widget-heading", `${fontColor?.includes("theme") && `color-${fontColor}`}`)}
                        style={classNames(fontColor?.includes("rgba") && fontColor)}
                    />
                    {(imageOptions.length > 1 && !options.hideMediaTypeMap && options.singleImageSlider) || options.multiImageSlider ? (
                        <Swiper {...sliderParams} otherProps className="single-img-slider" ref={this.swiper}>
                            {this.renderImages(imageOptions)}
                        </Swiper>
                    ) : (
                        this.renderImages(imageOptions)
                    )}
                    {!options.dynamicHeaderImage && this.renderModal(imageOptions)}
                    {(widgetOptions.showArrows && widgetOptions.singleImageSlider) || widgetOptions.multiImageSlider ? (
                        // eslint-disable-next-line max-len
                        <div
                            className={classNames("positioned-swiper-arrows", {
                                ["align-h-" + widgetOptions.arrowsHorizontalPosition]: !!widgetOptions.arrowsHorizontalPosition && widgetOptions.arrowsTogether,
                                ["align-v-" + widgetOptions.arrowsVerticalPosition]: !!widgetOptions.arrowsVerticalPosition,
                                "align-v-center": !widgetOptions.arrowsVerticalPosition,
                                "arrows-together": widgetOptions.arrowsTogether,
                                "arrows-apart": !widgetOptions.arrowsTogether,
                                ["arrows-" + widgetOptions.arrowStyle]: !!widgetOptions.arrowStyle,
                                "arrows-outside": widgetOptions.arrowsOutside,
                            })}
                        >
                            {options.showArrows && (
                                <React.Fragment>
                                    <div onClick={this.goPrev} className="swiper-button-prev" />
                                    <div onClick={this.goNext} className="swiper-button-next" />
                                </React.Fragment>
                            )}
                        </div>
                    ) : null}
                    {childOptions?.isEditable && getEditableButton(this.toggleModal, getI18nLocaleString(namespaceList.widgetImageGallery, "editImages", currentLocale, site))}
                    {isEditModalOpen && (
                        <GetEditableModal
                            toggleModal={this.toggleModal}
                            modalHeading={getI18nLocaleString(namespaceList.widgetImageGallery, "editImages", currentLocale, site)}
                            modalBodyData={this.renderImageEditorModalContent}
                            sizeOfModal="lg"
                            isEditModalOpen={isEditModalOpen}
                        />
                    )}
                </div>
            );
        }
        // lazy loading for all possible option in image gallery

        if (imageLoading) {
            if (widgetOptions.singleImageSlider) {
                return <Loader type="image" />;
            } else if (widgetOptions.multiImageSlider) {
                return <Loader views="slideShow" />;
            } else if (widgetOptions.showImageGalleryLayout) {
                return <Loader type="image-gallery-layout" views={widgetOptions.placementOfMultipleImages} />;
            }
            return <Loader type="static-image-gallery" />;
        }

        const { options } = this.props;
        if (widgetOptions.showImageGalleryLayout) {
            return imageOptions.length ? (
                <div
                    className={classNames(hideWidget, {
                        [className]: imageOptions.length > 1,
                        ["image-gallery-layout image-gallery-widget layout--xxs"]: imageOptions.length > 1,
                        ["single-image"]: imageOptions.length < 1,
                        ["apply-hover"]: !options.removeHoverEffect,
                    })}
                >
                    <LocalizedTitleAndLabel
                        localizedTitle={localizedWidgetTitle}
                        enableWidgetTitle={enableWidgetTitle}
                        useTitleHeadings={useTitleHeadings}
                        styleWidgetTitle={styleWidgetTitle}
                        className={classNames("widget-heading", `${fontColor?.includes("theme") && `color-${fontColor}`}`)}
                        style={classNames(fontColor?.includes("rgba") && fontColor)}
                    />

                    {this.renderLayout(imageOptions)}
                    {childOptions?.isEditable && getEditableButton(this.toggleModal, getI18nLocaleString(namespaceList.widgetImageGallery, "editImages", currentLocale, site))}
                    {isEditModalOpen && (
                        <GetEditableModal
                            toggleModal={this.toggleModal}
                            modalHeading={getI18nLocaleString(namespaceList.widgetImageGallery, "editImages", currentLocale, site)}
                            modalBodyData={this.renderImageEditorModalContent}
                            sizeOfModal="md"
                            isEditModalOpen={isEditModalOpen}
                        />
                    )}
                </div>
            ) : null;
        }
        return (
            <div
                className={classNames(
                    {
                        ["layout layout--xxs image-gallery-widget row no-gutters"]: imageOptions.length > 1 && !options.dynamicHeaderImage,
                        ["single-image"]: imageOptions.length < 1,
                        ["apply-hover"]: !options.removeHoverEffect,
                        ["resort-map"]: !!ImageType.MAP,
                    },
                    hideWidget,
                    className
                )}
            >
                <LocalizedTitleAndLabel
                    localizedTitle={localizedWidgetTitle}
                    enableWidgetTitle={enableWidgetTitle}
                    useTitleHeadings={useTitleHeadings}
                    styleWidgetTitle={styleWidgetTitle}
                    className={classNames("widget-heading", `${fontColor?.includes("theme") && `color-${fontColor}`}`)}
                    style={classNames(fontColor?.includes("rgba") && fontColor)}
                />
                {imageOptions.map((image: ImageOptions, index) => {
                    const videoUrl = this.getVideoUrl(image.url);
                    if (index > 4) {
                        return "";
                    }
                    if (index === 4) {
                        return (
                            <div
                                key={image.id}
                                // eslint-disable-next-line max-len
                                className={image.mediaType === MediaType.IMAGE ? image.className : `${image.className} video-added`}
                                {...(isFrontendEditable ? { onClick: handleFrontendEditModal } : { onClick: this.handleClickImage(index) })}
                            >
                                {image.mediaType === MediaType.IMAGE ? (
                                    <figure className="space-mb-0">
                                        <img
                                            loading="lazy"
                                            className="swiper-lazy"
                                            srcSet={`${this.createMobileImage(image.imageUrl)} 300w, ${this.createMobileImage(image.imageUrl)} 768w, ${image.imageUrl} 1280w`}
                                            sizes={"(min-width: 720px) calc(94.76vw - 274px), (min-width: 520px) calc(100vw - 96px), calc(100vw - 32px)"}
                                            src={image.imageUrl || ""}
                                            alt={image.alt || image.friendlyName ? image.alt || image.friendlyName : imageModalTitle}
                                        />
                                        <a className={classNames("image-overlay", { "image-overlay-bg": widgetOptions?.displayButton, "d-none": widgetOptions?.hideOverlay })}>
                                            <div className={classNames("db-mb-xs", { "d-none": widgetOptions.displayButton })}>
                                                <FontAwesome name="camera" size="2x" />
                                            </div>
                                            {`${imageOptions.length - 5}` > "0" && (
                                                <div className={classNames({ "d-none": widgetOptions.displayButton })}>
                                                    {`+${imageOptions.length - 5} ${getI18nLocaleString(namespaceList.admin, "seeMoreText", currentLocale, site)}`}
                                                </div>
                                            )}
                                            {`${imageOptions.length - 5}` > "0" && (
                                                <Button
                                                    {...(isFrontendEditable ? { onClick: handleFrontendEditModal } : { onClick: this.handleClickImage(0) })}
                                                    className={`button show-photos ${!widgetOptions.displayButton ? "d-none" : " "}`}
                                                >
                                                    <FontAwesome name="picture-o" />
                                                    <span>
                                                        {`+${imageOptions.length - 5}`}{" "}
                                                        {localizedButton?.optionalButtonText
                                                            ? localizedButton.optionalButtonText
                                                            : getI18nLocaleString(namespaceList.admin, "showAllPhotos", currentLocale, site)}
                                                    </span>
                                                </Button>
                                            )}
                                        </a>
                                    </figure>
                                ) : image.url ? (
                                    <React.Fragment>
                                        <div className="video-overlay"></div>
                                        {useOverlayThumbnailForVideo ? (
                                            renderThumbnailWithPlayButton(image, videoUrl, this.handleThumbnailVideo, showVideoModal, this.props.image?.originalUrl)
                                        ) : (
                                            <VideoPlayer url={image.mediaType === MediaType.VIDEO ? videoUrl : image.url} height={`${image.height}`} width={`${image.width}`} />
                                        )}
                                    </React.Fragment>
                                ) : null}
                            </div>
                        );
                    }
                    return (
                        <div
                            key={image.id}
                            className={
                                image.mediaType === MediaType.IMAGE
                                    ? imageOptions.length > 1
                                        ? image.className
                                        : "single-image-inner"
                                    : `${imageOptions.length > 1 ? image.className : "single-image-inner"} video-added`
                            }
                            {...(isFrontendEditable ? { onClick: handleFrontendEditModal } : { onClick: this.handleClickImage(index) })}
                        >
                            {image.mediaType === MediaType.IMAGE ? (
                                <figure className="space-mb-0">
                                    <img
                                        loading="lazy"
                                        className="swiper-lazy"
                                        srcSet={`${this.createMobileImage(image.imageUrl)} 300w, ${this.createMobileImage(image.imageUrl)} 768w, ${image.imageUrl} 1280w`}
                                        sizes={"(min-width: 720px) calc(94.76vw - 274px), (min-width: 520px) calc(100vw - 96px), calc(100vw - 32px)"}
                                        src={image.imageUrl || ""}
                                        alt={image.alt || image.friendlyName ? image.alt || image.friendlyName : imageModalTitle}
                                    />
                                    {!options.hideFigCaption && (
                                        <figcaption className={classNames("hidden-m", `${image.friendlyName ? "show-figcaption " : "hide-figcaption"}`)}>{image.alt || image.friendlyName}</figcaption>
                                    )}
                                </figure>
                            ) : image.url ? (
                                <React.Fragment>
                                    <div className="video-overlay"></div>
                                    {options.useOverlayThumbnailForVideo ? (
                                        renderThumbnailWithPlayButton(image, videoUrl, this.handleThumbnailVideo, showVideoModal, this.props.image?.originalUrl)
                                    ) : (
                                        <VideoPlayer url={image.mediaType === MediaType.VIDEO ? videoUrl : image.url} height={`${image.height}`} width={`${image.width}`} />
                                    )}
                                </React.Fragment>
                            ) : null}
                        </div>
                    );
                })}
                {this.renderModalVideo()}
                {this.renderModal(imageOptions)}
                {options.displayButton && (
                    <Button {...(isFrontendEditable ? { onClick: handleFrontendEditModal } : { onClick: this.handleClickImage(1) })} className={classNames("resort-map-button")}>
                        <span>{localizedButton?.optionalButtonText ? localizedButton.optionalButtonText : getI18nLocaleString(namespaceList.admin, "showAllPhotos", currentLocale, site)}</span>
                    </Button>
                )}
                {childOptions?.isEditable && getEditableButton(this.toggleModal, getI18nLocaleString(namespaceList.widgetImageGallery, "editImages", currentLocale, site))}
                {isEditModalOpen && (
                    <GetEditableModal
                        toggleModal={this.toggleModal}
                        modalHeading={getI18nLocaleString(namespaceList.widgetImageGallery, "editImages", currentLocale, site)}
                        modalBodyData={this.renderImageEditorModalContent}
                        sizeOfModal="md"
                        isEditModalOpen={isEditModalOpen}
                    />
                )}
            </div>
        );
    }

    private getColumnClassNameForRightLeft = (numberOfMultipleImages: number) => {
        switch (numberOfMultipleImages) {
            case 3:
                return "col-md-8";
            case 5:
                return "col-lg-6 col-md-8";
            default:
                return "";
        }
    };

    private getRightLayout = (imageOptions: ImageOptions[], numberOfMultipleImages: number) => {
        const dynamicClassNames = classNames({
            "third-layout": numberOfMultipleImages === 3,
            "fifth-layout": numberOfMultipleImages === 5,
        });
        const {
            options: widgetOptions,
            context: { currentLocale, site },
        } = this.props;
        const localizedMainImage: LocalizedMainImage | null = getLocalizedContent({ site, currentLocale, localizedContent: widgetOptions.localizedMainImage });
        return (
            <div className={classNames("image-gallery-layout--right", dynamicClassNames, { "row no-gutters": imageOptions.length > 1 })}>
                {imageOptions[0] ? (
                    <div className={imageOptions.length > 1 ? `${this.getColumnClassNameForRightLeft(numberOfMultipleImages)} space-ph-0 main-layout-image` : "single-image-inner"}>
                        {this.renderContent(imageOptions[0])}
                        <div className="main-layout-image__text">
                            <h5>{localizedMainImage?.mainImageContent}</h5>
                        </div>
                    </div>
                ) : (
                    <img loading="lazy" className="fallback-image" src={fallbackImage.originalUrl} />
                )}

                <div className={`${numberOfMultipleImages > 3 ? "d-lg-block" : "d-md-block"} col d-none space-ph-0 other-layout-images`}>
                    {numberOfMultipleImages !== 3 ? this.withoutOverlayImage(imageOptions, [1, 2]) : this.withOverlayImage(imageOptions, [1, 2])}
                </div>
                {numberOfMultipleImages > 3 && (
                    <div className={`${numberOfMultipleImages > 5 ? "d-lg-block" : "d-md-block"} col d-none space-ph-0 other-layout-images`}>
                        {numberOfMultipleImages !== 5 ? this.withoutOverlayImage(imageOptions, [3, 4]) : this.withOverlayImage(imageOptions, [3, 4])}
                    </div>
                )}
                {this.renderModalVideo()}
                {this.renderModal(imageOptions)}
            </div>
        );
    };

    private getLeftLayout = (imageOptions: ImageOptions[], numberOfMultipleImages: number) => {
        const dynamicClassNames = classNames({
            "third-layout": numberOfMultipleImages === 3,
            "fifth-layout": numberOfMultipleImages === 5,
        });
        const {
            options: widgetOptions,
            context: { currentLocale, site },
        } = this.props;
        const localizedMainImage: LocalizedMainImage | null = getLocalizedContent({ site, currentLocale, localizedContent: widgetOptions.localizedMainImage });
        return (
            <div className={classNames("image-gallery-layout--left", `${dynamicClassNames} ${imageOptions.length > 1 ? "row no-gutters" : ""}`)}>
                {numberOfMultipleImages > 3 && (
                    <div className={`${numberOfMultipleImages > 5 ? "d-lg-block" : "d-md-block"} col d-none space-ph-0 other-layout-images`}>
                        {numberOfMultipleImages !== 5 ? this.withoutOverlayImage(imageOptions, [3, 4]) : this.withOverlayImage(imageOptions, [3, 4])}
                    </div>
                )}
                <div className={`${numberOfMultipleImages > 3 ? "d-lg-block" : "d-md-block"} col d-none space-ph-0 other-layout-images`}>
                    {numberOfMultipleImages !== 3 ? this.withoutOverlayImage(imageOptions, [1, 2]) : this.withOverlayImage(imageOptions, [1, 2])}
                </div>
                {imageOptions[0] ? (
                    <div className={`${imageOptions.length > 1 ? this.getColumnClassNameForRightLeft(numberOfMultipleImages) + " space-ph-0 main-layout-image" : "single-image-inner"}`}>
                        {this.renderContent(imageOptions[0])}
                        <div className="main-layout-image__text">
                            <h5>{localizedMainImage?.mainImageContent}</h5>
                        </div>
                    </div>
                ) : (
                    <img loading="lazy" className="fallback-image" src={fallbackImage.originalUrl} />
                )}
                {this.renderModalVideo()}
                {this.renderModal(imageOptions)}
            </div>
        );
    };

    private getTopLayout = (imageOptions: ImageOptions[], numberOfMultipleImages: number) => {
        const dynamicClassNames = classNames({
            "third-layout": numberOfMultipleImages === 3,
            "fifth-layout": numberOfMultipleImages === 5,
        });
        const {
            options: widgetOptions,
            context: { currentLocale, site },
        } = this.props;
        const localizedMainImage: LocalizedMainImage | null = getLocalizedContent({ site, currentLocale, localizedContent: widgetOptions.localizedMainImage });
        const repeatedClassNames = ["col space-ph-0 other-layout-images"];
        return (
            <div className={classNames("image-gallery-layout--top", `${dynamicClassNames} ${imageOptions.length > 1 ? "row no-gutters" : ""}`)}>
                {numberOfMultipleImages === 3 && (
                    <React.Fragment>
                        <div className={`col-md-6 ${repeatedClassNames}`}>{this.withoutOverlayImage(imageOptions, [1])}</div>
                        <div className={`col-md-6 ${repeatedClassNames}`}>{this.withOverlayImage(imageOptions, [2])}</div>
                    </React.Fragment>
                )}

                {numberOfMultipleImages === 5 && (
                    <React.Fragment>
                        <div className={`col-lg-3 ${repeatedClassNames}`}>{this.withoutOverlayImage(imageOptions, [1])}</div>
                        <div className={`col-lg-3 ${repeatedClassNames}`}>{this.withoutOverlayImage(imageOptions, [2])}</div>
                        <div className={`col-lg-3 col-md-6 d-none d-md-block ${repeatedClassNames}`}>{this.withoutOverlayImage(imageOptions, [3])}</div>
                        <div className={`col-lg-3 col-md-6 d-none d-md-block ${repeatedClassNames}`}>{this.withOverlayImage(imageOptions, [4])}</div>
                    </React.Fragment>
                )}
                {imageOptions[0] ? (
                    <div className={`${imageOptions.length > 1 ? "col-md-12 space-ph-0 main-layout-image" : "single-image-inner"}`}>
                        {this.renderContent(imageOptions[0])}
                        <div className="main-layout-image__text">
                            <h5>{localizedMainImage?.mainImageContent}</h5>
                        </div>
                    </div>
                ) : (
                    <img loading="lazy" className="fallback-image" src={fallbackImage.originalUrl} />
                )}
                {this.renderModalVideo()}
                {this.renderModal(imageOptions)}
            </div>
        );
    };

    private getBottomLayout = (imageOptions: ImageOptions[], numberOfMultipleImages: number) => {
        const dynamicClassNames = classNames({
            "third-layout": numberOfMultipleImages === 3,
            "fifth-layout": numberOfMultipleImages === 5,
        });
        const {
            options: widgetOptions,
            context: { currentLocale, site },
        } = this.props;
        const localizedMainImage: LocalizedMainImage | null = getLocalizedContent({ site, currentLocale, localizedContent: widgetOptions.localizedMainImage });
        const repeatedClassNames = ["col space-ph-0 other-layout-images"];
        return (
            <div className={classNames("image-gallery-layout--bottom", `${dynamicClassNames} ${imageOptions.length > 1 ? "row no-gutters" : ""}`)}>
                {imageOptions[0] ? (
                    <div className={`${imageOptions.length > 1 ? "col-md-12 space-ph-0 main-layout-image" : "single-image-inner"}`}>
                        {this.renderContent(imageOptions[0])}
                        <div className="main-layout-image__text">
                            <h5>{localizedMainImage?.mainImageContent}</h5>
                        </div>
                    </div>
                ) : (
                    <img loading="lazy" className="fallback-image" src={fallbackImage.originalUrl} />
                )}
                {numberOfMultipleImages === 3 && (
                    <React.Fragment>
                        <div className={`col-md-6 ${repeatedClassNames}`}>{this.withoutOverlayImage(imageOptions, [1])}</div>
                        <div className={`col-md-6 ${repeatedClassNames}`}>{this.withOverlayImage(imageOptions, [2])}</div>
                    </React.Fragment>
                )}

                {numberOfMultipleImages === 5 && (
                    <React.Fragment>
                        <div className={`col-lg-3 ${repeatedClassNames}`}>{this.withoutOverlayImage(imageOptions, [1])}</div>
                        <div className={`col-lg-3 ${repeatedClassNames}`}>{this.withoutOverlayImage(imageOptions, [2])}</div>
                        <div className={`col-lg-3 col-md-6 d-none d-md-block ${repeatedClassNames}`}>{this.withoutOverlayImage(imageOptions, [3])}</div>
                        <div className={`col-lg-3 col-md-6 d-none d-md-block ${repeatedClassNames}`}>{this.withOverlayImage(imageOptions, [4])}</div>
                    </React.Fragment>
                )}
                {this.renderModalVideo()}
                {this.renderModal(imageOptions)}
            </div>
        );
    };

    private withoutOverlayImage = (imageOptions: ImageOptions[], renderIndices: number[]) => {
        const { options: widgetOptions, handleFrontendEditModal, isFrontendEditable, image } = this.props;
        const { imageModalTitle, showVideoModal } = this.state;
        const { useOverlayThumbnailForVideo } = this.props.options;

        const diff = renderIndices[renderIndices.length - 1] - imageOptions.length;
        return (
            <React.Fragment>
                {imageOptions.map((imageOption, index) =>
                    imageOption ? (
                        renderIndices.includes(index) && (
                            <figure
                                key={index}
                                className={`space-mb-0 ${renderIndices.length > 1 ? "other-layout" : imageOption.mediaType === MediaType.VIDEO ? "video-added" : ""}`}
                                {...(isFrontendEditable ? { onClick: handleFrontendEditModal } : { onClick: this.handleClickImage(index) })}
                            >
                                {imageOption.mediaType === MediaType.IMAGE ? (
                                    <React.Fragment>
                                        <img
                                            loading="lazy"
                                            className="swiper-lazy"
                                            srcSet={`${this.createMobileImage(imageOption.imageUrl)} 300w, ${this.createMobileImage(imageOption.imageUrl)} 768w, ${imageOption.imageUrl} 1280w`}
                                            sizes={"(min-width: 720px) calc(94.76vw - 274px), (min-width: 520px) calc(100vw - 96px), calc(100vw - 32px)"}
                                            src={imageOption.imageUrl || ""}
                                            alt={imageOption.alt || imageOption.friendlyName ? imageOption.alt || imageOption.friendlyName : imageModalTitle}
                                        />
                                        {!widgetOptions.hideFigCaption && (
                                            <figcaption className={classNames({ [`${widgetOptions.figCaptionFontSize}`]: !!widgetOptions.figCaptionFontSize })}>
                                                {imageOption.alt || imageOption.friendlyName}
                                            </figcaption>
                                        )}
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <div className="video-overlay"></div>
                                        {useOverlayThumbnailForVideo ? (
                                            renderThumbnailWithPlayButton(
                                                imageOption,
                                                imageOption.mediaType === MediaType.VIDEO ? this.getVideoUrl(imageOption.url) : imageOption.url,
                                                this.handleThumbnailVideo,
                                                showVideoModal,
                                                image?.originalUrl
                                            )
                                        ) : (
                                            <VideoPlayer
                                                url={imageOption.mediaType === MediaType.VIDEO ? this.getVideoUrl(imageOption.url) : imageOption.url}
                                                height={`${imageOption.width}`}
                                                width={`${imageOption.height}`}
                                            />
                                        )}
                                    </React.Fragment>
                                )}
                            </figure>
                        )
                    ) : (
                        <img key={index} loading="lazy" className="fallback-image" src={fallbackImage.originalUrl} />
                    )
                )}
                {imageOptions.length < renderIndices[renderIndices.length - 1] + 1 && <img className="fallback-image" src={fallbackImage.originalUrl} />}
                {renderIndices.length > 1 && diff > 1 && <img className="fallback-image" src={fallbackImage.originalUrl} />}
            </React.Fragment>
        );
    };

    private withOverlayImage = (imageOptions: ImageOptions[], renderIndices: number[]) => {
        const {
            options: widgetOptions,
            context: { currentLocale, site },
            isFrontendEditable,
            handleFrontendEditModal,
            image,
        } = this.props;
        const { imageModalTitle, showVideoModal } = this.state;
        const diff = renderIndices[renderIndices.length - 1] - imageOptions.length;
        const { useOverlayThumbnailForVideo } = this.props.options;
        const localizedButton: LocalizedButton | null = getLocalizedContent({ site, currentLocale, localizedContent: widgetOptions.localizedButton });
        return (
            <React.Fragment>
                {imageOptions.map((imageOption, index) =>
                    imageOption ? (
                        renderIndices.includes(index) && (
                            <figure
                                key={index}
                                className={`space-mb-0 ${renderIndices.length > 1 ? "other-layout" : imageOption.mediaType === MediaType.VIDEO ? "video-added" : ""}`}
                                {...(isFrontendEditable ? { onClick: handleFrontendEditModal } : { onClick: this.handleClickImage(index) })}
                            >
                                {imageOption.mediaType === MediaType.IMAGE ? (
                                    <React.Fragment>
                                        <img
                                            loading="lazy"
                                            className="swiper-lazy"
                                            srcSet={`${this.createMobileImage(imageOption.imageUrl)} 300w, ${this.createMobileImage(imageOption.imageUrl)} 768w, ${imageOption.imageUrl} 1280w`}
                                            sizes={"(min-width: 720px) calc(94.76vw - 274px), (min-width: 520px) calc(100vw - 96px), calc(100vw - 32px)"}
                                            src={imageOption.imageUrl || ""}
                                            alt={imageOption.alt || imageOption.friendlyName ? imageOption.alt || imageOption.friendlyName : imageModalTitle}
                                        />
                                        {!widgetOptions.hideFigCaption && (
                                            <figcaption className={classNames({ [`${widgetOptions.figCaptionFontSize}`]: !!widgetOptions.figCaptionFontSize })}>
                                                {imageOption.alt || imageOption.friendlyName}
                                            </figcaption>
                                        )}
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        {useOverlayThumbnailForVideo ? (
                                            renderThumbnailWithPlayButton(
                                                imageOption,
                                                imageOption.mediaType === MediaType.VIDEO ? this.getVideoUrl(imageOption.url) : imageOption.url,
                                                this.handleThumbnailVideo,
                                                showVideoModal,
                                                image?.originalUrl
                                            )
                                        ) : (
                                            <VideoPlayer
                                                url={imageOption.mediaType === MediaType.VIDEO ? this.getVideoUrl(imageOption.url) : imageOption.url}
                                                height={`${imageOption.width}`}
                                                width={`${imageOption.height}`}
                                            />
                                        )}
                                    </React.Fragment>
                                )}
                                {renderIndices[renderIndices.length - 1] === index && (
                                    <a className={classNames("image-overlay", { "image-overlay-bg": widgetOptions?.displayButton, "d-none": widgetOptions?.hideOverlay })}>
                                        <div className={classNames("db-mb-xs", { "d-none": widgetOptions.displayButton })}>
                                            <FontAwesome name="camera" size="2x" />
                                        </div>
                                        {imageOptions.length - index > 0 && (
                                            <div className={classNames({ "d-none": widgetOptions.displayButton })}>{`+${imageOptions.length - index} ${getI18nLocaleString(
                                                namespaceList.admin,
                                                "seeMoreText",
                                                currentLocale,
                                                site
                                            )}`}</div>
                                        )}
                                        {imageOptions.length - index > 0 && (
                                            <Button
                                                {...(isFrontendEditable ? { onClick: handleFrontendEditModal } : { onClick: this.handleClickImage(index) })}
                                                className={`button show-photos ${!widgetOptions.displayButton ? "d-none" : " "}`}
                                            >
                                                <FontAwesome name="picture-o" />
                                                <span>
                                                    {`+${imageOptions.length - index}`}{" "}
                                                    {localizedButton?.optionalButtonText
                                                        ? localizedButton.optionalButtonText
                                                        : getI18nLocaleString(namespaceList.admin, "showAllPhotos", currentLocale, site)}
                                                </span>
                                            </Button>
                                        )}
                                    </a>
                                )}
                            </figure>
                        )
                    ) : (
                        <img key={index} loading="lazy" className="fallback-image" src={fallbackImage.originalUrl} />
                    )
                )}
                {imageOptions.length < renderIndices[renderIndices.length - 1] + 1 && <img loading="lazy" className="fallback-image" src={fallbackImage.originalUrl} />}
                {renderIndices.length > 1 && diff > 1 && <img loading="lazy" className="fallback-image" src={fallbackImage.originalUrl} />}
            </React.Fragment>
        );
    };
    // jscpd:ignore-end
    private renderLayout = (imageOptions: ImageOptions[]) => {
        const { options: widgetOptions } = this.props;
        switch (widgetOptions.placementOfMultipleImages) {
            case "right":
                return this.getRightLayout(imageOptions, 3);
            case "left":
                return this.getLeftLayout(imageOptions, 3);
            case "top":
                return this.getTopLayout(imageOptions, 3);
            case "bottom":
                return this.getBottomLayout(imageOptions, 3);
            case "right-five-layout":
                return this.getRightLayout(imageOptions, 5);
            case "left-five-layout":
                return this.getLeftLayout(imageOptions, 5);
            case "top-five-layout":
                return this.getTopLayout(imageOptions, 5);
            case "bottom-five-layout":
                return this.getBottomLayout(imageOptions, 5);
        }
    };

    private goNext = () => {
        if (this.swiper.current?.swiper) {
            this.swiper.current.swiper.slideNext();
        }
    };
    private goPrev = () => {
        if (this.swiper.current?.swiper) {
            this.swiper.current.swiper.slidePrev();
        }
    };

    private renderImages = (imageOptions: ImageOptions[]) => {
        const firstImage = imageOptions?.[0];
        const { showVideoModal } = this.state;
        const { useOverlayThumbnailForVideo } = this.props.options;
        if (!this.props.options.dynamicHeaderImage) {
            return (
                imageOptions.length &&
                imageOptions.map((image: ImageOptions, index: number) => {
                    const videoUrl = this.getVideoUrl(image.url);
                    const {
                        options: widgetOptions,
                        context: { currentLocale, site },
                        isFrontendEditable,
                        handleFrontendEditModal,
                    } = this.props;
                    const localizedButton: LocalizedButton | null = getLocalizedContent({ site, currentLocale, localizedContent: widgetOptions.localizedButton });
                    if (image.alt || image.friendlyName) {
                        this.setState({ captionCheck: true });
                    }
                    return (
                        <div
                            key={index}
                            className={image.mediaType === MediaType.IMAGE ? "without-video" : "video-added"}
                            {...(isFrontendEditable ? { onClick: handleFrontendEditModal } : { onClick: this.handleClickImage(index) })}
                        >
                            {image.imageType !== ImageType.MAP ? (
                                <figure className="space-mb-0">
                                    <img
                                        loading="lazy"
                                        className="swiper-lazy"
                                        srcSet={`${this.createMobileImage(image.imageUrl)} 300w, ${this.createMobileImage(image.imageUrl)} 768w, ${image.imageUrl} 1280w`}
                                        sizes={"(min-width: 720px) calc(94.76vw - 274px), (min-width: 520px) calc(100vw - 96px), calc(100vw - 32px)"}
                                        src={image.imageUrl || ""}
                                        alt={image.alt || image.friendlyName ? image.alt || image.friendlyName : this.props.imageModalTitle}
                                    />
                                    {!widgetOptions.hideFigCaption && (
                                        <figcaption className={classNames("hidden-m", `${image.friendlyName ? "show-figcaption " : "hide-figcaption"}`)}>{image.alt || image.friendlyName}</figcaption>
                                    )}
                                    {!widgetOptions.hideOverlay && (
                                        <a
                                            className={classNames({
                                                "image-overlay image-overlay-bg": widgetOptions?.displayButton && widgetOptions.singleImageSlider,
                                                "image-overlay image-overlay-single": widgetOptions?.showOverlay,
                                            })}
                                        >
                                            <div className={classNames("db-mb-xs", { "d-none": widgetOptions.displayButton })}>
                                                <FontAwesome name="camera" size="2x" />
                                            </div>
                                            <Button
                                                {...(isFrontendEditable ? { onClick: handleFrontendEditModal } : { onClick: this.handleClickImage(index) })}
                                                className={`button show-photos ${!widgetOptions.displayButton ? "d-none" : " "}`}
                                            >
                                                <FontAwesome name="picture-o" />
                                                <span>
                                                    {`+${imageOptions.length - index}`}{" "}
                                                    {localizedButton?.optionalButtonText
                                                        ? localizedButton.optionalButtonText
                                                        : getI18nLocaleString(namespaceList.admin, "showAllPhotos", currentLocale, site)}
                                                </span>
                                            </Button>
                                        </a>
                                    )}
                                </figure>
                            ) : image.imageType === ImageType.MAP && !this.props.options.hideMediaTypeMap ? (
                                <img
                                    loading="lazy"
                                    className="swiper-lazy"
                                    srcSet={`${this.createMobileImage(image.imageUrl)} 300w, ${this.createMobileImage(image.imageUrl)} 768w, ${image.imageUrl} 1280w`}
                                    sizes={"(min-width: 720px) calc(94.76vw - 274px), (min-width: 520px) calc(100vw - 96px), calc(100vw - 32px)"}
                                    src={image.imageUrl || ""}
                                    alt={image.alt || image.friendlyName ? image.alt || image.friendlyName : this.props.imageModalTitle}
                                ></img>
                            ) : image.url ? (
                                <React.Fragment>
                                    {useOverlayThumbnailForVideo ? (
                                        renderThumbnailWithPlayButton(image, videoUrl, this.handleThumbnailVideo, showVideoModal, this.props.image?.originalUrl)
                                    ) : (
                                        <VideoPlayer url={image.mediaType === MediaType.VIDEO ? videoUrl : image.url} height={`${image.height}`} width={`${image.width}`} />
                                    )}
                                </React.Fragment>
                            ) : null}
                        </div>
                    );
                })
            );
        } else if (this.props.options.dynamicHeaderImage) {
            return (
                <img
                    loading="lazy"
                    className="swiper-lazy"
                    srcSet={`${this.createMobileImage(firstImage.imageUrl)} 300w, ${this.createMobileImage(firstImage.imageUrl)} 768w, ${firstImage.imageUrl} 1280w`}
                    sizes={"(min-width: 720px) calc(94.76vw - 274px), (min-width: 520px) calc(100vw - 96px), calc(100vw - 32px)"}
                    src={firstImage.imageUrl || ""}
                    alt={firstImage.alt || firstImage.friendlyName ? firstImage.alt || firstImage.friendlyName : this.props.imageModalTitle}
                />
            );
        }
    };

    private renderModal = (imageOptions: any) => {
        const { showModal, slidePosition, imageModalTitle, showVideoModal } = this.state;
        const { options: widgetOptions, image } = this.props;
        return (
            <GalleryModal
                showModal={showModal}
                imageOptions={imageOptions}
                slidePosition={slidePosition.toString()}
                imageModalTitle={imageModalTitle}
                options={widgetOptions}
                imageOrignalUrl={image?.originalUrl}
                handleThumbnailVideo={this.handleThumbnailVideo}
                showVideoModal={showVideoModal}
                handleModal={this.handleClick}
                getImageUrl={getImageUrl}
                getVideoUrl={this.getVideoUrl}
                onPauseVideo={this.pauseVideo}
            />
        );
    };

    private renderModalVideo = () => {
        const { showVideoModal, modalVideoUrl } = this.state;
        return (
            <Modal isOpen={showVideoModal} toggle={this.handleVideoModal} size="md" className="video-overlay-modal">
                <ModalHeader tag="h4" toggle={this.handleVideoModal}>
                    <FontAwesome name="close"></FontAwesome>
                </ModalHeader>
                <ModalBody>
                    <VideoPlayer url={modalVideoUrl || ""} />
                </ModalBody>
            </Modal>
        );
    };

    private handleClick = () => {
        this.pauseVideo();
        this.setState({ showModal: !this.state.showModal });
    };
    private handleVideoModal = () => {
        this.setState({ showVideoModal: !this.state.showVideoModal });
    };

    private handleClickImage = (index: number) => () => {
        this.pauseVideo();
        this.setState({ showModal: !this.state.showModal, slidePosition: index });
    };
    private handleThumbnailVideo = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, modalVideoUrl?: string) => {
        event.stopPropagation();
        const updatedUrl = modalVideoUrl?.replace("autoplay=0", "autoplay=1");
        this.setState({ modalVideoUrl: updatedUrl });
        this.handleVideoModal();
    };

    private pauseVideo = () => {
        // TODO: use better approach to pause the video, this method is creating flickering
        if (typeof document !== "undefined") {
            const iframe = document.getElementsByTagName("iframe");
            for (let i = 0; i < iframe.length; i++) {
                const item = iframe.item(i);
                const src = item ? item.getAttribute("src") : "";
                if (src && item) {
                    item.setAttribute("src", src);
                }
            }
        }
    };

    private createMobileImage(url: string): string {
        if (url) {
            return url.replace("t_mcms_larger", "t_mcms_normal").replace("t_mcms_medium", "t_mcms_normal").replace("t_mcms_original", "t_mcms_normal");
        }
        return url;
    }

    private getVideoUrl = (url: string) => {
        const urlArray = url.split("/");
        const videoId = url.indexOf("?v=") > -1 ? url.split("?v=")[1] : urlArray[urlArray.length - 1];
        return `https://www.youtube-nocookie.com/embed/${videoId}?enablejsapi=1&modestbranding=1&autoplay=0&loop=0&controls=0&rel=0&autohide=1`;
    };

    public renderImageEditorModalContent = () => {
        const { imageOptions, isUploadingAndDeleting, isDelete, isImageFileTypeInvalid, isImageUploaded } = this.state;
        const {
            context: { currentLocale, site },
        } = this.props;
        const { imageModalTitle } = this.state;
        return (
            <React.Fragment>
                <div className="edit-image-loader">
                    {isUploadingAndDeleting && <div className="edit-image-overlay" />}
                    {isUploadingAndDeleting && (
                        <div className="loader-wrapper">
                            <FontAwesome name="spinner" className={classNames("searchfacet-progress", "in-progress")} />
                        </div>
                    )}
                    <div className="alerts-upload-image-wrapper">
                        <div className="upload-image">
                            <Input name="image-file" id="image-file" type="file" accept="image/*" onChange={this.handleImageUpload} className="form-control" />
                            <label className="choose-file" htmlFor="image-file">
                                <FontAwesome name="cloud-upload" />
                                {getI18nLocaleString(namespaceList.admin, "chooseImages", currentLocale, site)}
                            </label>
                        </div>
                        <div className="alerts">
                            {isDelete === "Success" && (
                                <UncontrolledAlert color="success">{getI18nLocaleString(namespaceList.widgetImageGallery, "imageDeleted", currentLocale, site)}</UncontrolledAlert>
                            )}
                            {isDelete === "Failed" && (
                                <UncontrolledAlert color="danger">{getI18nLocaleString(namespaceList.widgetImageGallery, "imageFailedToDelete", currentLocale, site)}</UncontrolledAlert>
                            )}
                            {isImageFileTypeInvalid && (
                                <UncontrolledAlert color="danger">{getI18nLocaleString(namespaceList.widgetImageGallery, "invalidImageFileType", currentLocale, site)}</UncontrolledAlert>
                            )}
                            {isImageUploaded === "Success" && (
                                <UncontrolledAlert color="success">{getI18nLocaleString(namespaceList.widgetImageGallery, "imageUploaded", currentLocale, site)}</UncontrolledAlert>
                            )}
                            {isImageUploaded === "Failed" && (
                                <UncontrolledAlert color="danger">{getI18nLocaleString(namespaceList.widgetImageGallery, "imageFailedToUpload", currentLocale, site)}</UncontrolledAlert>
                            )}
                        </div>
                    </div>
                    <div className="editable-modal__card-wrapper">
                        <div className="linked-images">
                            {imageOptions.map((image, index) => (
                                <Card key={index}>
                                    <div className="card__image">
                                        <CardImg top loading="lazy" src={image.imageUrl || ""} alt={image.alt || image.friendlyName ? image.alt || image.friendlyName : imageModalTitle} />
                                        <div className="overlay-image">
                                            <div className="button" onClick={() => this.handleDeleteImage(image.imageId)}>
                                                <FontAwesome name="trash" />
                                            </div>
                                        </div>
                                    </div>
                                    <CardBody>
                                        <CardTitle>{image.fileName}</CardTitle>
                                    </CardBody>
                                </Card>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    public toggleModal = () => {
        this.setState({ isEditModalOpen: !this.state.isEditModalOpen, isDelete: undefined, isImageFileTypeInvalid: false, isImageUploaded: undefined });
    };

    private handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const imageFile = event.target.files?.[0];
        this.setState({ isImageFileTypeInvalid: false, isImageUploaded: undefined }, () => {
            if (imageFile?.type.includes("image")) {
                this.setState({ isUploadingAndDeleting: true });
                this.uploadImage(imageFile);
            } else {
                this.setState({ isImageFileTypeInvalid: true });
            }
        });
    };

    private uploadImage = async (file: File) => {
        const { context } = this.props;
        const formData = new FormData();
        formData.append("file", file);
        const env = await getMxtsEnv(context, context.currentLocale.code);
        const uploadedImage = await MxtsApi.uploadImages(env, { file: formData })
            .then((imageResponse) => {
                this.setState({ isImageUploaded: "Success", isUploadingAndDeleting: false });
                return imageResponse;
            })
            .catch(() => {
                this.setState({ isImageUploaded: "Failed", isUploadingAndDeleting: false });
            });
    };

    public handleDeleteImage = (imageId?: number) => {
        this.setState({ isDelete: undefined, isUploadingAndDeleting: true });
        this.unlinkImageFromImageManager(imageId);
    };

    public unlinkImageFromImageManager = async (imageId?: number) => {
        const { options: widgetOptions, context } = this.props;
        const { imageOptions } = this.state;
        const imageIds = imageOptions.map((image) => image.imageId!).filter((id) => id !== imageId);
        const [env, content] = await Promise.all([
            getMxtsEnv(context, context.currentLocale.code),
            getContent({ ...this.props, options: widgetOptions, skipContentTypeSelectorCheck: true }) as Promise<Exclude<Content, Resort[]>>,
        ]);
        const imageManagerId = content?.imageManagerId;
        let updatedImages;
        if (imageId && imageManagerId) {
            updatedImages = await MxtsApi.addImagesToImageManager(env, { resourceType: "OBJECTBASE", imageIds }, [{ key: "imageManagerId", value: imageManagerId }]);
            MxtsApi.clearCacheImagesPerManager((cacheKey: string) => cacheKey.includes("imageManagerId") && cacheKey.includes(imageManagerId.toString()));
        }
        if (updatedImages && updatedImages?.length) {
            const remainingImages = await context.mxtsApi.imagesPerManager(env, {}, [{ key: "imageManagerId", value: imageManagerId }]);
            if (remainingImages.length) {
                const imageOptions = remainingImages.map((image, index) => getMXTSOptions(image, index, widgetOptions));
                this.setState({ imageOptions, isDelete: "Success", isUploadingAndDeleting: false });
            }
        } else {
            this.setState({ isDelete: "Failed", isUploadingAndDeleting: false });
        }
    };

    private renderContent = (imageOptions: ImageOptions) => {
        const { options: widgetOptions, isFrontendEditable, handleFrontendEditModal, image } = this.props;
        const { imageModalTitle, showVideoModal } = this.state;
        const { useOverlayThumbnailForVideo } = this.props.options;
        return (
            <figure className="space-mb-0" {...(isFrontendEditable ? { onClick: handleFrontendEditModal } : { onClick: this.handleClickImage(0) })}>
                {imageOptions.mediaType === MediaType.IMAGE ? (
                    <React.Fragment>
                        <img
                            loading="lazy"
                            className="swiper-lazy"
                            srcSet={`${this.createMobileImage(imageOptions.imageUrl)} 300w, ${this.createMobileImage(imageOptions.imageUrl)} 768w, ${imageOptions.imageUrl} 1280w`}
                            sizes={"(min-width: 720px) calc(94.76vw - 274px), (min-width: 520px) calc(100vw - 96px), calc(100vw - 32px)"}
                            src={imageOptions.imageUrl || ""}
                            alt={imageOptions.alt || imageOptions.friendlyName ? imageOptions.alt || imageOptions.friendlyName : imageModalTitle}
                        />
                        {!widgetOptions.hideFigCaption && (
                            <figcaption className={classNames({ [`${widgetOptions.figCaptionFontSize}`]: !!widgetOptions.figCaptionFontSize })}>
                                {imageOptions.alt || imageOptions.friendlyName}
                            </figcaption>
                        )}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="video-overlay"></div>
                        {useOverlayThumbnailForVideo ? (
                            renderThumbnailWithPlayButton(
                                imageOptions,
                                imageOptions.mediaType === MediaType.VIDEO ? this.getVideoUrl(imageOptions.url) : imageOptions.url,
                                this.handleThumbnailVideo,
                                showVideoModal,
                                image?.originalUrl
                            )
                        ) : (
                            <VideoPlayer
                                url={imageOptions.mediaType === MediaType.VIDEO ? this.getVideoUrl(imageOptions.url) : imageOptions.url}
                                height={`${imageOptions.height}`}
                                width={`${imageOptions.width}`}
                            />
                        )}
                    </React.Fragment>
                )}
            </figure>
        );
    };
}

function mapStateToProps(state: State): ImageGalleryStoreProps {
    return {
        myEnvState: state.myEnvState,
        dynamicFilter: state.dynamicFilter,
        availabilityState: state.availabilityState,
        isFrontendEditable: state.frontendPageEditState.isFrontEndEditable,
    };
}

const ImageGalleryWithEditModal = withEditModal(ImageGalleryBase);
const ImageGellery = connect<ImageGalleryStoreProps>(mapStateToProps)(ImageGalleryWithEditModal);

export default wrapProps<ImageGalleryBaseProps>(ImageGellery);
