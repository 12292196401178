import * as FontAwesome from "react-fontawesome";
import * as React from "react";

import { ImageOptions } from "./";
import { MediaType } from "./ImageGalleryWidget";
import { VideoPlayer } from "./VideoPlayer";
import { fallbackImage } from "../../../media";

export function renderThumbnailWithPlayButton(
    image: ImageOptions,
    videoUrl: string,
    handleThumbnailVideo: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, modalVideoUrl?: string) => void,
    showVideoModal?: boolean,
    originalUrl?: string
) {
    const { mediaType, url, height, width } = image;
    return (
        <div className="video-overlay-container">
            <React.Fragment>
                <img src={originalUrl || fallbackImage.originalUrl} className="video-overlay-image" onClick={(event) => event.stopPropagation()} />
                <a className="play-button" onClick={(event) => handleThumbnailVideo(event, videoUrl)}>
                    <FontAwesome name={showVideoModal ? "pause" : "play"} />
                </a>
            </React.Fragment>
            <VideoPlayer url={mediaType === MediaType.VIDEO ? videoUrl : url} height={`${height}`} id="thumbnailWithPlayVideo" width={`${width}`} />
        </div>
    );
}
