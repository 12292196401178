import * as React from "react";

interface VideoPlayerProps {
    height?: string;
    width?: string;
    url: string;
    id?: string;
    className?: string;
}

export class VideoPlayer extends React.PureComponent<VideoPlayerProps> {
    constructor(props: VideoPlayerProps = { id: "ytplayer", url: "" }) {
        super(props);
    }

    public render(): JSX.Element | null {
        const { url, width, height, id, className } = this.props;
        return <iframe width={width} height={height} id={id} src={url} className={className} allow="autoplay" />;
    }
}
