import * as classNames from "classnames";

import { CMSProvidedProperties, CMSProviderProperties } from "../../../containers/cmsProvider.types";
import { Content, getContent, getParentContent } from "../../../utils/content.util";
import { Image, ImageSpec, fallbackImage, findImagesBySpecs, renderImageByCloudinary } from "../../../media";
import { WidgetOptions as ImageGalleryWidgetOptions, ImageOptions, LocalizedOptions, LocalizedTitle, TARGETS } from "./";
import { Image as MXTSImageType, PagedResult, Resource } from "@maxxton/cms-mxts-api";
import { RESORT, ServerSideProps, UNIT, WidgetOptionsMap } from "../../../components/utils";
import { Resort, getMxtsEnv } from "../../mxts";

import ReactIdSwiper from "react-id-swiper";
import { StringMultiSelectOption } from "../../mxts/selectOption.types";
import { findMultiSelectStyleClassNames } from "../../../themes";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import { globalApiContext } from "../../../containers/CmsProvider";

export const getOptions = (imageSpecs: ImageSpec[], img: Image, index: number, context: CMSProviderProperties): ImageOptions => {
    const locale = context.currentLocale.locale;
    const currentImageSpec: ImageSpec | undefined = imageSpecs.find((spec) => spec.imageId === img.id);
    let alt;
    let preset;
    let friendlyName;
    if (currentImageSpec) {
        const localizedOptions = currentImageSpec.options.localized?.find((l) => l.locale === locale);
        alt = localizedOptions && localizedOptions.alt;
        friendlyName = localizedOptions && localizedOptions.friendlyName;
        preset = currentImageSpec.options.preset;
    }
    const imageUrl = preset ? getImageUrl(img.presets[preset].url || "") : getImageUrl(img.presets.large.url || "");
    const className = getImageClassnames(index);
    // eslint-disable-next-line max-len
    return {
        id: img.id,
        imageUrl,
        alt,
        friendlyName,
        className,
        url: img.url || "",
        mediaType: img.mediaType || "",
        width: currentImageSpec!.options.width,
        height: currentImageSpec!.options.height,
        imageType: img.imageType || "",
    };
};

export function getImageClassnames(index: number): string {
    return classNames({
        ["layout__item col-sm-6 space-mb-xxs large-image-size"]: index === 0,
        ["layout__item hidden-s col-sm-6 space-mb-xxs large-image-size"]: index === 1,
        ["layout__item col-6 col-sm-4 space-mb-xxs smaller-image-size"]: index === 2 || index === 4,
        ["layout__item hidden-s col-6 col-sm-4 space-mb-xxs smaller-image-size"]: index === 3,
    });
}

export const getImageSpecs = (context: CMSProviderProperties, options: ImageGalleryWidgetOptions) => {
    const { currentLocale, site } = context;
    const localizedImageWidgetOptions: LocalizedOptions | null = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedOptions });
    let imageSpecs: ImageSpec[] | undefined;
    if (localizedImageWidgetOptions?.localizedImageGallery?.length) {
        imageSpecs = localizedImageWidgetOptions.localizedImageGallery;
    } else {
        imageSpecs = options.imageSpecs;
    }
    return imageSpecs;
};

export const getImageModalTitleAndClasses = (context: CMSProviderProperties, options: ImageGalleryWidgetOptions) => {
    const { currentLocale, site } = context;
    const localizedTitle: LocalizedTitle | null = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedTitle });
    const imageModalTitle = localizedTitle?.label || "";
    const styleClasses = findMultiSelectStyleClassNames(context.theme, TARGETS, options.styleIds || []);
    return { imageModalTitle, styleClasses };
};
export const getImageOptions = async (imageSpecs: ImageSpec[], context: CMSProviderProperties) => {
    const images: Image[] = imageSpecs ? await findImagesBySpecs(context, imageSpecs) : [];

    const imageOptions: ImageOptions[] = imageSpecs ? images.map((img: Image, index) => getOptions(imageSpecs!, img, index, context)) : [];
    return imageOptions;
};

export const getImageUrl = (url: string): string => {
    const index = url ? url.lastIndexOf("t_") : 0;
    const filteredUrl = index > 0 ? url.slice(0, index) + "/" + url.slice(index) : url;
    return filteredUrl?.replace("t_newyse", "t_mcms");
};

export const forceUpdateSwiper = (swiper: typeof ReactIdSwiper) => {
    // Taken from https://github.com/kidjp85/react-id-swiper/issues/379#issuecomment-610787442
    swiper?.current?.swiper?.loopDestroy();
    setTimeout(() => {
        swiper?.current?.swiper?.update();
        swiper?.current?.swiper?.loopCreate();
    }, 1000);
};

export const getMXTSOptions = (img: MXTSImageType, index: number, widgetOptions: ImageGalleryWidgetOptions): ImageOptions => {
    const friendlyName = img.caption ? img.caption.split("-").join(" ") : img.name ? img.name : "";
    let imageUrl: string;
    switch (widgetOptions.dynamicImageSize) {
        case "large":
            imageUrl = getImageUrl(img.mediaType === "IMAGE" ? img.urls.large.replace("t_newyse_large", "t_mcms_larger/f_auto") || "" : img.url);
            break;
        case "medium":
            imageUrl = getImageUrl(img.mediaType === "IMAGE" ? img.urls.medium.replace("t_newyse_normal", "t_mcms_medium/f_auto") || "" : img.url);
            break;
        case "original":
            imageUrl = getImageUrl(img.mediaType === "IMAGE" ? img.urls.original.replace("t_newyse_original", "t_mcms_original/f_auto") || "" : img.url);
            break;
        default:
            imageUrl = getImageUrl(img.mediaType === "IMAGE" ? img.urls.large.replace("t_newyse_large", "t_mcms_larger/f_auto") || "" : img.url);
            break;
    }
    const className = getImageClassnames(index);
    return {
        id: index.toString(),
        imageUrl,
        friendlyName,
        className,
        url: img.url || "",
        mediaType: img.mediaType || "",
        fileName: img.fileName,
        imageId: img.imageId,
        imageType: img.imageType || "",
    };
};

export const loadImageOptions = async <T extends keyof WidgetOptionsMap>(props: ServerSideProps<T>, imageOptions: ImageOptions[]) => {
    const { context, options, dynamicFilter, myEnvState } = props;
    const widgetOptions = options as ImageGalleryWidgetOptions;
    const unitId = dynamicFilter?.unitid || myEnvState?.ownerState?.selectedUnitId;
    if (widgetOptions.showDynamicImages) {
        const [env, content] = await Promise.all([
            getMxtsEnv(context, context.currentLocale.code),
            getContent({ ...props, options: widgetOptions, skipContentTypeSelectorCheck: true }) as Promise<Exclude<Content, Resort[]>>,
        ]);

        let dynamicImages: MXTSImageType[] = [];

        if (content) {
            let imageManagerId: number | undefined | null = content.imageManagerId;
            dynamicImages = await context.mxtsApi.imagesPerManager(env, {}, [{ key: "imageManagerId", value: imageManagerId }]);

            if (!dynamicImages.length) {
                const parentContent = await getParentContent(context.mxtsApi, { content, env });
                imageManagerId = parentContent?.imageManagerId;
                dynamicImages = imageManagerId ? await context.mxtsApi.imagesPerManager(env, {}, [{ key: "imageManagerId", value: imageManagerId }]) : [];
            }
        } else if (widgetOptions.contentType === UNIT && !unitId && dynamicFilter?.resourceid) {
            // Note: Add resource's imageOptions as fallback when UnitID NOT present.
            const resource = await context.mxtsApi.resources(env, { size: 1, resourceIds: [dynamicFilter.resourceid] }).then((res: PagedResult<Resource>) => res.content);
            if (resource[0]?.imageManagerId) {
                dynamicImages = await context.mxtsApi.imagesPerManager(env, {}, [{ key: "imageManagerId", value: resource[0].imageManagerId }]);
            }
        } else if (widgetOptions.contentType === RESORT && dynamicFilter?.resortids) {
            const resort = await context.mxtsApi.resorts(env, { size: 1, resortIds: [dynamicFilter.resortids[0]] }).then((res: PagedResult<Resort>) => res.content);
            if (resort[0]?.imageManagerId) {
                dynamicImages = await context.mxtsApi.imagesPerManager(env, {}, [{ key: "imageManagerId", value: resort[0].imageManagerId }]);
            }
        }
        if (widgetOptions.chooseImageTypes) {
            dynamicImages = dynamicImages.filter((dynamicImage) => widgetOptions.imageTypes?.some((requiredImageType: StringMultiSelectOption) => requiredImageType.value === dynamicImage.imageType));
        }

        if (dynamicImages?.length) {
            imageOptions = dynamicImages.map((img: MXTSImageType, index) => getMXTSOptions(img, index, widgetOptions)).concat(imageOptions);
        }
    }
    if (imageOptions.length === 0) {
        const fallbackImages = await getFallbackImageOptions(props?.context);
        if (fallbackImages) {
            imageOptions = [fallbackImages];
            console.error("No image configured");
        }
    }

    const uniqueIds: string[] = [];
    const uniqueImageOptions = imageOptions.filter((imageOption: ImageOptions) => {
        const isDuplicate = uniqueIds.includes(imageOption.id);
        if (!isDuplicate) {
            uniqueIds.push(imageOption.imageUrl);
            return true;
        }
        return false;
    });
    return { imageOptions: uniqueImageOptions, imageLoading: false };
};

export const getFallbackImageOptions = async (context: CMSProvidedProperties) => {
    const apiContext = context?.mxtsApi ? context : globalApiContext();
    const fallback: Image | null = await renderImageByCloudinary(apiContext, fallbackImage)!;
    return fallback ? getOptions([fallbackImage], fallback, 0, context) : undefined;
};
